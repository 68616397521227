<!-- 购物账单 -->
<template>
	<div :class="themeClass" class="capitalBill">
		<div class="top flex-row-space-between-center margin-t-30">
			<div class="title font-size18 font-weight700 font-color-333">提货账单</div>
			<div :loading="downloadLoading" style="cursor: pointer;" class="topBtn  text-center  excelBtn"
			 @click="ExportEXcel">
				<div class="img1"></div>
				<div style="display: inline-block;">账单下载</div>
				
			 </div>
		</div>
		<div class="flex-row-align-center">
			<span style="width: 50px;">
				店铺
			</span>
			<el-select  v-model="fCompanyId" placeholder="请选择店铺" style="width: 140px;margin-right:10px;" @change="CompanyChange">
				<el-option v-for="(item,index) in CompanyNamelist" :key="index" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<!-- <span :class="activeName=='yearMonth'?'activeClass':''" style="width: 50px;">
				年月
			</span>
			<el-select  v-model="balance" placeholder="请选择年月" style="width: 140px;margin-right:10px;" @change="monthChange">
				<el-option v-for="(item,index) in balanceOptions" :key="index" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<div class="tabs">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane class="pointer-color-theme" label="昨天" name="yesterday"></el-tab-pane>
					<el-tab-pane class="pointer-color-theme" label="今天" name="today"></el-tab-pane>
				</el-tabs>
			</div> -->

      <div class="choose-date" >
          <!-- 新时间组件 -->
          <Timequery @change="getTimeall"/>
          <!-- 新时间组件 -->
			</div>
			<!-- <div class="inputBor">
				<el-date-picker v-model="searchDate[0]" type="date" placeholder="请选择开始日期"></el-date-picker>
				<span>至</span>
				<el-date-picker v-model="searchDate[1]" type="date" placeholder="请选择结束日期"></el-date-picker>
			</div> -->
			<div class="pointer topBtn  bg-color-theme text-center font-color-fff queryBtn margin-l-10" @click="query">查询</div>
			
		</div>
		<div class="tab margin-t-13 flex-row margin-b-10">
			<div class="flex-row">
				<div class="tabItem text-center" :class="tabSelect == 2 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(2)">账单概览</div>
				<div class="tabItem text-center" :class="tabSelect == 0 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(0)">仓库汇总账单</div>
				<div class="tabItem text-center" :class="tabSelect == 1 ? 'bg-fff color-theme border-lr-F2F2F2' : ''" @click="tabClick(1)">账单明细</div>
			</div>
			<div class="tab-right"></div>
		</div>
		<!-- <div v-if="billWalletSummary.length==0&&moneyDashSummary.length==0&&accountPaySummary.length==0" class="flex-row-center-center"> -->
		<!-- <svg-icon icon-class="NoDataAvailable" /> -->
		<!-- </div> -->

		<div v-loading="loading" v-if="tabSelect == 0" class="tab-content2">
			<el-table :data="tableData" stripe border :row-style="{ height: '36px' }" :header-cell-style="{ 'text-align': 'center', background: '#F2F2F2', 'font-weight': '400', color: '#000' }">
				<!-- <el-table-column prop="fCompanyName" label="店铺" width="276" align="left" show-overflow-tooltip></el-table-column> -->
				<el-table-column prop="fDepotName" label="仓库" width="220" align="center"></el-table-column>
				<el-table-column prop="fGoodsName" label="品种" width="165" align="center"></el-table-column>
				<!-- <el-table-column prop="fGoodsPacking" label="包装方式" width="166" align="right"></el-table-column> -->
				<el-table-column prop="fDeliveryAmount" label="实发量" align="left"></el-table-column>
			</el-table>
			<div class="flex-row-center-flex-end margin-t-20 margin-b-30"  >
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
				 :pagesize="pagesize" :total="total"></pagination>
			</div>
		</div>
		<div v-loading="loading" v-if="tabSelect == 1" class="tab-content2">
			<el-table :data="tableData" stripe border :row-style="{ height: '36px' }" :header-cell-style="{ 'text-align': 'center', background: '#e9f3ff', 'font-weight': '500', color: '#000', 'font-size': '13px' }">
				<!-- <el-table-column prop="fCompanyName" label="店铺" width="200" align="left" show-overflow-tooltip fixed></el-table-column> -->
				<el-table-column prop="fDeliveryOrderNumber" label="派车单号" width="165" align="center"></el-table-column>
				<el-table-column prop="fOrderNumber" label="订单号" width="165" align="center"></el-table-column>
				<el-table-column prop="fDepotBillNumber" label="生产厂提货单号" width="165" align="center"></el-table-column>
				<el-table-column prop="fKeepDate" label="发货日期" width="120" align="center"></el-table-column>
				<!-- <el-table-column prop="fGradeName" label="客户等级" width="100" align="center"></el-table-column> -->
				<el-table-column prop="fTradingModel" label="交易模式" width="100" align="center"></el-table-column>
				<el-table-column prop="fDepotName" label="仓库" width="100" align="left"></el-table-column>
				<el-table-column prop="fGoodsFullName" label="商品名称" width="200" align="left"></el-table-column>
				<el-table-column prop="fGoodsPropertieData" label="包装方式" width="80" align="center"></el-table-column>
				<el-table-column prop="fWareNumber" label="水泥编号" width="100" align="center"></el-table-column>
				<el-table-column prop="fDelivery" label="提货方式" width="80" align="center"></el-table-column>
				<el-table-column prop="fUnitName" label="计量单位" width="80" align="center"></el-table-column>
				<el-table-column prop="fScheduleVanAmount" label="派车量" width="100" align="right"></el-table-column>
				<el-table-column prop="fGross" label="毛重" width="100" align="right"></el-table-column>
				<el-table-column prop="fTare" label="皮重" width="100" align="right"></el-table-column>
				<el-table-column prop="fDeliveryAmount" label="实发量" width="100" align="right"></el-table-column>
				<el-table-column prop="fGoodsAgePrice" label="货款价" width="100" align="right"></el-table-column>
				<el-table-column prop="fSettlePrice" label="结算单价" width="100" align="right"></el-table-column>
				<el-table-column prop="fcFreightPrice" label="运价" width="100" align="right"></el-table-column>
				<el-table-column prop="fUsePoint" label="使用积分数" width="100" align="right"></el-table-column>
				<!-- <el-table-column prop="fPointPrice" label="积分价" width="100" align="right"></el-table-column> -->
				<el-table-column prop="fPointMoney" label="积分金额" width="100" align="right"></el-table-column>
				<el-table-column prop="fGoodsMoney" label="货款金额" width="100" align="right"></el-table-column>
				<el-table-column prop="fcFreightMoney" label="运费金额" width="80" align="right"></el-table-column>
				<el-table-column prop="fTareTime" label="皮重时间" width="192" align="center"></el-table-column>
				<el-table-column prop="fGrossTime" label="毛重时间" width="192" align="center"></el-table-column>
				<el-table-column prop="fPlateNumber" label="车号" width="100" align="left"></el-table-column>
				<el-table-column prop="fDriver" label="司机" width="130" align="left"></el-table-column>
				<el-table-column prop="fDriverPhone" label="司机电话" width="130" align="left"></el-table-column>
				<!-- <el-table-column prop="fcsPhone" label="客服电话" width="130" align="left"></el-table-column> -->
				<el-table-column prop="fConsignee" label="收货人" width="80" align="left"></el-table-column>
				<el-table-column prop="fConsigneePhone" label="收货人电话" width="130" align="left"></el-table-column>
				<el-table-column prop="fProjectName" label="工程名称" width="220" align="left"></el-table-column>
				<el-table-column prop="fGovernmenArea" label="行政区域" width="140" align="left"></el-table-column>
				<el-table-column prop="fDeliveryAddress" label="收货地址" width="500" align="left"></el-table-column>
				<el-table-column prop="fCreator" label="制单人" width="100" align="center"></el-table-column>
				<el-table-column prop="fCreateTime" label="制单时间" width="192" align="center"></el-table-column>
			</el-table>
			<div class="flex-row-center-flex-end margin-t-20 margin-b-30"  >
				<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage"
				 :pagesize="pagesize" :total="total"></pagination>
			</div>
		</div>
		<div v-loading="loading" v-if="tabSelect == 2" class="tab-content2">
			<div class="overview">
				<div class='leftbox'>
					<div style="font-size: 13px; color:#ffffff;font-weight:700;padding: 10px 16px;">* 货款金额与该店铺应开具发票金额一致</div>
					<div style="background-color: #ecf3fd;border-radius:20px 20px 0 0;padding:0 16px; height:144px;box-sizing: border-box">
						<div class="flex-row-space-between">
							<div class="item">
								<div style='color:#8F949E;font-size:13px;padding-top: 20px;padding-bottom: 8px;'>提货量</div>
								<div style='color:#1B2541;font-size:15px;font-weight: 700;'>{{fReceivedAmount}}</div>
							</div>
							<div class="item">
								<div style='color:#8F949E;font-size: 13px;padding-top: 20px;padding-bottom: 8px;'>总费用</div>
								<div style='color:#1B2541;font-size:15px;font-weight: 700;'>{{fMoney}}</div>
							</div>
						</div>
						<div class="flex-row-space-between">
							<div class="item">
								<div style='color:#8F949E;font-size:13px;padding-top: 12px;padding-bottom: 8px;'>积分抵扣</div>
								<div style='color:#1B2541;font-size:15px;font-weight: 700;'>{{fPointMoney}}</div>
							</div>
							<div class="item">
								<div style='color:#8F949E;font-size:13px;padding-top: 12px;padding-bottom: 8px;'>货款金额</div>
								<div style='color:#1B2541;font-size:15px;font-weight: 700;'>{{fSumMoney}}</div>
							</div>
						</div>
					</div>
					<!-- <div class="item">
						<span>提货量：</span>
						<span>{{fReceivedAmount}}</span>
					</div>
					<div class="item">
						<span>总费用：</span>
						<span>{{fMoney}}</span>
					</div>
					<div class="item">
						<span>积分抵扣：</span>
						<span>{{fPointMoney}}</span>
					</div>
					<div class="item">
						<span>货款金额：</span>
						<span>{{fSumMoney}}</span>
					</div>
					<div style="font-size: 17px; color: red;">* 货款金额与该店铺应开具发票金额一致</div> -->
				</div>
				<div class='rightbox'>
					<div id="main"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
  import Timequery from "@/components/Timequery.vue"
	import pagination from '@/components/pagination/pagination.vue';
	import * as echarts from 'echarts';
	export default {
		components: {
			pagination,
      Timequery
		},
		props: {},
		data() {
			return {
				fReceivedAmount: 0, // 提货量
				fMoney: 0, // 总费用
				fPointMoney: 0, // 积分抵扣
				fSumMoney: 0,  // 货款金额
				fCompanyId: 5,
				CompanyNamelist: [], // 店铺列表
				
				balance:'',
				balanceOptions: [],
				startMouth:'',
				endMouth:'',
				activeName: 'today',
				searchDate: ['', ''],
				loading: false,
				defaultTime: '', //时间默认值
				value: 2, //钱包类型下拉框v-model绑定的值
				typeSelect: 0, //钱包类型value
				value1: [new Date(), new Date()], //时间
				fBeginTime: '',
				fEndTime: '',
				tabSelect: 2, //tab选中项

				tableData: [], //订单列表表格数据
				requestUrl: '', //订单列表接口url
				downloadLoading: false,
				total: 0, //总条数
				pagesize: 15, //每页显示条目个数
				currentPage: 1, //当前页
				columnList: [], //导出表头列表
				tablebles: [{
						prop: 'fCompanyName',
						label: '店铺',
						width: '200',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fDeliveryOrderNumber',
						label: '派车单号',
						width: '165',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fOrderNumber',
						label: '订单号',
						width: '165',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fDepotBillNumber',
						label: '生产厂提货单号',
						width: '165',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fKeepDate',
						label: '发货日期',
						width: '165',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fGradeName',
						label: '客户等级',
						width: '100',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fTradingModel',
						label: '交易模式',
						width: '100',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fDepotName',
						label: '仓库',
						width: '100',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fGoodsFullName',
						label: '商品名称',
						width: '200',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fGoodsPropertieData',
						label: '包装方式',
						width: '50',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fWareNumber',
						label: '水泥编号',
						width: '100',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fDelivery',
						label: '提货方式',
						width: '50',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fUnitName',
						label: '计量单位',
						width: '50',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fScheduleVanAmount',
						label: '派车量',
						width: '50',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fGross',
						label: '毛重',
						width: '70',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fTare',
						label: '皮重',
						width: '70',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fDeliveryAmount',
						label: '实发量',
						width: '80',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fGoodsAgePrice',
						label: '货款价',
						width: '80',
						align: 'right',
						fixed: true
					},
					{
						prop: 'fSettlePrice',
						label: '结算单价',
						width: '80',
						align: 'right',
						fixed: true
					},
					{
						prop: 'fcFreightPrice',
						label: '运价',
						width: '80',
						align: 'right',
						fixed: true
					},
					{
						prop: 'fUsePoint',
						label: '使用积分数',
						width: '100',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fPointPrice',
						label: '积分价',
						width: '80',
						align: 'right',
						fixed: true
					},
					{
						prop: 'fPointMoney',
						label: '积分金额',
						width: '100',
						align: 'right',
						fixed: true
					},
					{
						prop: 'fGoodsMoney',
						label: '货款金额',
						width: '100',
						align: 'right',
						fixed: true
					},
					{
						prop: 'fcFreightMoney',
						label: '运费金额',
						width: '80',
						align: 'right',
						fixed: true
					},
					{
						prop: 'fTareTime',
						label: '皮重时间',
						width: '192',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fGrossTime',
						label: '毛重时间',
						width: '192',
						align: 'center',
						fixed: true
					},
					{
						prop: 'fPlateNumber',
						label: '车号',
						width: '100',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fDriver',
						label: '司机',
						width: '130',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fDriverPhone',
						label: '司机电话',
						width: '130',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fcsPhone',
						label: '客服电话',
						width: '130',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fConsignee',
						label: '收货人',
						width: '80',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fConsigneePhone',
						label: '收货人电话',
						width: '130',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fProjectName',
						label: '工程名称',
						width: '220',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fGovernmenArea',
						label: '行政区域',
						width: '140',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fDeliveryAddress',
						label: '收货地址',
						width: '1000',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fCreator',
						label: '制单人',
						width: '220',
						align: 'left',
						fixed: true
					},
					{
						prop: 'fCreateTime',
						label: '制单时间',
						width: '192',
						align: 'center',
						fixed: true
					},
				]
			};
		},
		created() {
			// this.getdayValue();
		},
		mounted() {

			//页面一开始就需要拿到时间数据
			this.acquireMonth()
			// const start = new Date();
			// const end = new Date();
			// // this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90));
			// this.searchDate[0] = end;
			// this.searchDate[1] = end;
			// console.log('this.value1', this.value1);

		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		filters: {
			//   取绝对值
			abs(val) {
				return Math.abs(val);
			}
		},
		methods: {
      getTimeall (val) {
        console.log(val)
        this.searchDate[0] = val[0]
			  this.searchDate[1] = val[1]
        this.getDetails();
		  	this.acquireDate();
		   	this.getShopList()
      },
			// 获取图标数据
			getEchartsData(valueList) {
				let Xdata = [] 
				let Ydata = []
				let data = []
				if (valueList) {
					valueList.forEach(item => {
						if(item.fKeepDate) {
							item.fKeepDate = this.getDate(item.fKeepDate)
							let index = data.findIndex(item1 => {
								return item1.date == item.fKeepDate
							})
							if (index != -1) {
								data[index].value = data[index].value + item.fGoodsMoney
							} else {
								let obj = {}
								obj.date = item.fKeepDate
								obj.value = item.fGoodsMoney
								data.push(obj)
							}
						}
					})
					console.log(data)
					data.forEach(item => {
						Xdata.push(item.date)
						Ydata.push(item.value)
					})
				}
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(document.getElementById('main'));
				var that = this
				// 绘制图表
				myChart.setOption({
					legend: {
						bottom: '0%',
					},
					tooltip: {
					    trigger: 'axis',
					    axisPointer: {
					      type: 'shadow'
					    }
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '10%',
						top: '15%',
						containLabel: true
						},
					xAxis: {
						data: Xdata,
						axisLabel: {
						    color: '#666666'
						}
					},
					yAxis: {
						type: 'value',
						axisLabel: {
						    // 使用 formatter 函数格式化标签文本
						    formatter: '{value} 元', // 在这里设置单位
							color: '#666666'
						}
					},
					series: [
						{
							name: '货款金额(元)',
							type: 'bar',
							data: Ydata,
							label: {
							   show: true,
							   position: 'top',
							   color: '#0173FE',
							   valueAnimation: true,
							   formatter: function (params) {
									return that.NumFormat(params.value)
								}
							},
							itemStyle: {  
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [ // 设置柱子的颜色为蓝色 
								    { offset: 0, color: '#6BAFFF' },
								    { offset: 1, color: '#0173FE' }
								])  
							}  
						}
					]
				});
			},
			// 获取店铺列表
			getShopList() {
				this.ApiRequestPost("api/mall/ebsale/deliver-order/get-shoping-bill-statistics-shop", {
				}).then(
					(res) => {
						if (res.obj) {
							let obj = {}
							res.obj.forEach(item => {
								this.fCompanyId = item.fShopID
								obj.label = item.fShopName
								obj.value = item.fShopID 
								this.CompanyNamelist.push(obj)
							})
						}
					},
					(error) => {}
				);
			},
			// 店铺筛选
			CompanyChange() {
				this.getDetails()
			},
			//获取当前时间的前12个月
			dateAcquire(){
				    var dataArr = [];
				    var data = new Date();
				    var year = data.getFullYear();
				    data.setMonth(data.getMonth()+1, 1)//获取到当前月份,设置月份
				    for (var i = 0; i < 12; i++) {
				        data.setMonth(data.getMonth() - 1);//每次循环一次 月份值减1
				        var m = data.getMonth() + 1;
				        m = m < 10 ? "0" + m : m;
						dataArr.push({
							value: data.getTime(),
							label: data.getFullYear() + "年" + (m) + "月"
						})
				    }
					this.balanceOptions = JSON.parse(JSON.stringify(dataArr))
			},
			//时间更改
			monthChange(e){
				this.activeName = "yearMonth"
				this.searchDate[0] = this.getMonthFirst(e)
				this.searchDate[1] = this.getMonthLast(e)
				this.getDetails()
			},
			//格式化时间
			getDateNoDay(time) {
			  if (time == null || time == '') {
			    return '';
			  }
			  let date = new Date(time);
			  let year = date.getFullYear(); //年
			  let month = date.getMonth() + 1; //月
			  let day = date.getDate(); //日
			  month = month > 9 ? month : '0' + month;
			  day = day > 9 ? day : '0' + day;
			  return `${year}年${month}月`;
			},
			//获取某个月的第一天
			getMonthFirst(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth(), 1)
			 	return enddate
			},
			//获取某个月的最后一天
			getMonthLast(date){
				const stringDate = new Date(date)
			 	const enddate = new Date(stringDate.getFullYear(), stringDate.getMonth() + 1, 0)
			 	return enddate
			},
			acquireDate(){
				this.dateAcquire()
			},
			acquireMonth(){
				this.ApiRequestPostNOMess("api/mall/sys/account-date/get-curret-date", {
				}).then(
					(res) => {
					// console.log(res,'89898989898')
					this.startMouth = res.obj.fStartDate
					this.endMouth = res.obj.fEndDate
					},
					(error) => {}
				);
			},
			// 快捷切换时间
			handleClick(tab, event) {
				if (this.searchDate == null) {
					this.searchDate = ['', ''];
				}
				const start = new Date();
				const end = new Date();
				this.balance = "";
				console.log(start)
				switch (this.activeName) {
					case 'all':
						this.searchDate[0] = '';
						this.searchDate[1] = '';
						break;
					case 'today':
						this.searchDate[0] = start.setTime(start.getTime());
						console.log(this.searchDate[0])
						this.searchDate[1] = end;
						break;
					case 'yesterday':
						this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24));
						this.searchDate[1] = this.searchDate[0];
						break;
					case 'oneMonth':
						this.searchDate[0] = this.startMouth;
						this.searchDate[1] = this.endMouth;
						break;
				}
				this.currentPage = 1;
				this.getDetails();
			},

			//pageSize（每页个数） 改变时会触发
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getDetails();
			},
			getdayValue() {
				let a = new Date();
				this.defaultTime = this.getDate(a);
			},
			// tab切换
			tabClick(val) {
				this.tabSelect = val;
				this.value = val;
				this.getDetails();
			},
			// 点击查询
			query() {
				this.getDetails();
			},
			// 点击
			cardClick(val) {
				this.value = val;
				this.getDetails();
				this.tabClick(1);
			},
			// 获取明细数据
			getDetails() {
				this.loading=true;
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				if (this.value == 0) {
					this.requestUrl = 'api/mall/ebsale/deliver-order/get-shopping-bill-statistics';
				} else if(this.value == 1) {
					this.requestUrl = 'api/mall/ebsale/deliver-order/get-shopping-bill';
				} else if(this.value == 2) {
					this.requestUrl = 'api/mall/ebsale/deliver-order/get-shoping-bill-statistics-overview';
				}
				if (this.value == 2) {
					this.ApiRequestPostNOMess('api/mall/ebsale/deliver-order/get-shopping-bill', {
						fShopID: this.fCompanyId,
						fBeginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
						fEndTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
						// maxResultCount: this.pagesize,
						filter: '',
						sorting: '',
						// skipCount: (this.currentPage - 1) * this.pagesize
					}).then(
						result => {
							console.log('获取明细数据', result.obj.items)
							let echartsdata = result.obj.items
							this.getEchartsData(echartsdata)
							this.$forceUpdate();
						},
						rej => {
							this.loading=false;
						}
					);
				}
				this.ApiRequestPostNOMess(this.requestUrl, {
					fShopID: this.fCompanyId,
					fBeginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					fEndTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					maxResultCount: this.pagesize,
					filter: '',
					sorting: '',
					skipCount: (this.currentPage - 1) * this.pagesize
				}).then(
					result => {
						if (this.value == 2) {
							this.fReceivedAmount = this.NumFormat(result.obj.fReceivedAmount)
							this.fMoney  = this.NumFormat(result.obj.fMoney) 
							this.fPointMoney  = this.NumFormat(result.obj.fPointMoney) 
							this.fSumMoney  = this.NumFormat(result.obj.fSumMoney) 
						} else {
							console.log('获取明细数据', result)
							this.tableData = [];
							result.obj.items.forEach(item => {
							  if(item.fBusinessTime !=null || item.fTareTime !=null || item.fGrossTime != null || item.fKeepDate !=null){
								item.fBusinessTime = this.getTime(item.fBusinessTime);
								item.fTareTime = this.getTime(item.fTareTime);
								item.fGrossTime = this.getTime(item.fGrossTime);
								item.fKeepDate = this.getDate(item.fKeepDate);	
							}
								
							});
              console.log(this.value,12312313)
							this.tableData = result.obj.items;
              if(this.value == 0){
                this.tableData.forEach(item=>{
                  item.fDeliveryAmount = this.NumFormat(item.fDeliveryAmount) //实发量
                })
              }else if(this.value == 1){
                this.tableData.forEach(item=>{
                  item.fDeliveryAmount = this.NumFormat(item.fDeliveryAmount) //实发量
                  item.fScheduleVanAmount = this.NumFormat(item.fScheduleVanAmount) //派车量
                  item.fGross = this.NumFormat(item.fGross) //毛重
                  item.fTare = this.NumFormat(item.fTare) //皮重
                  item.fDeliveryAmount = this.NumFormat(item.fDeliveryAmount) //实发量
                  item.fGoodsAgePrice = this.NumFormat(item.fGoodsAgePrice) //货款价
                  item.fSettlePrice = this.NumFormat(item.fSettlePrice) //结算单价
                  item.fcFreightPrice = this.NumFormat(item.fcFreightPrice) //运价
                  item.fUsePoint = this.NumFormat(item.fUsePoint) //使用积分数
                  item.fPointMoney = this.NumFormat(item.fPointMoney) //积分金额
                  item.fGoodsMoney = this.NumFormat(item.fGoodsMoney) //货款金额
                  item.fcFreightMoney = this.NumFormat(item.fcFreightMoney) //运费金额
                })
              }
							this.total = result.obj.totalCount
						}
						
						this.$forceUpdate();
						this.loading=false;
					},
					rej => {
						this.loading=false;
					}
				);
			},
      //千分符方法
      NumFormat(value) {
         let isNegative = false;
         if (value < 0 && value > -1) {
         if (value.toString().indexOf('-') == 0) {
           isNegative = true;
         }
         }
         if (!value) return '0.00';
         value = Number(value).toFixed(2).toString();
         var intPart = parseInt(Number(value)); //获取整数部分
         var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,'); //将整数部分逢三一断
         var floatPart = ".00"; //预定义小数部分
         var value2Array = value.split(".");
         if (isNegative) {
           intPartFormat = '-' + intPartFormat;
         }
          //=2表示数据有小数位
         if (value2Array.length == 2) {
          floatPart = value2Array[1].toString(); //拿到小数部分
         if (floatPart.length == 1) { //补0,实际上用不着
          return intPartFormat + "." + floatPart + '0';
         } else {
          return intPartFormat + "." + floatPart;
         }
         } else {
          return intPartFormat + floatPart;
         }
      },
			// EXcel导出
			ExportEXcel() {
				this.downloadLoading = true;
				console.log('列表数据', this.tablebles);
				this.columnList = [];
				for (var i = 0; i < this.tablebles.length; i++) {
					this.columnList.push({
						Key: this.tablebles[i].prop,
						Value: this.tablebles[i].label
					});
				}
				if (this.searchDate.length > 1) {
					if (this.getDate(this.searchDate[1]) < this.getDate(this.searchDate[0])) {
						return this.$message('结束时间不能早于开始时间');
					}
				}
				if (this.tableData.length == 0) {
					return this.$message('导出数据不能为空！');
				}
				this.requestUrl = 'api/mall/ebsale/deliver-order/down-shopping-bill';
				this.ApiRequestPostNOMess(this.requestUrl, {
					model: this.columnList,
					fBeginTime: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
					fEndTime: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
					filter: ''
				}).then(
					result => {
						console.log('获取EXcel导出数据', result);
						window.open(result.obj);
						this.downloadLoading = false;
					},
					rej => {
						this.downloadLoading = false;
					}
				);
			}
		}
	};
</script>

<style lang="scss" scoped>
  .choose-date {
    width: 406px;
    display: flex;
    align-items: center;
  }
	/deep/ .el-tabs__header {
		margin: 0;
	}

	/deep/ .el-tabs__item {
		padding: 0;
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-tabs__active-bar {
		display: none;
	}

	/deep/ .el-tabs__nav-wrap::after {
		background-color: transparent;
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	.inputBor {
		width: 280px;
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	/deep/ .el-input__icon {
		line-height: 22px;
	}

	/deep/ .el-tabs__item.is-active {
		color: #fff;

		@include themify($themes) {
			background-color: themed('themes_color');
		}
	}

	.top {
		width: 100%;
		height: 32px;

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 240px;
		}

		.search-box {
			width: 270px;
		}

		.search-btn {
			width: 79px;
			height: 32px;
			line-height: 32px;
			background: #f8f7fa;
			border-radius: 4px;
			border: 1px solid #f2f2f2;
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.bg-color-theme {
		@include themify($themes) {
			background: themed('themes_color');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.margin-t-8 {
		margin-top: 8px;
	}

	.capitalBill {
		width: 921px;
	}

	.top {
		width: 100%;
		height: 32px;
	}

	.selectBox {
		width: 121px;
		margin-left: 15px;
	}

	.chooseDate {
		width: 354px;
	}

	.excelBtn {
		margin-left: 6px;
		width: 120px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		color: #000;
		margin-bottom: 10px;
		font-size: 16px;
	}

	.queryBtn {
		width: 75px;
		height: 32px;
		line-height: 32px;
		text-align: center;
	}

	/deep/ .el-input {
		height: 32px;
		line-height: 32px;
	}

	.selectBox {
		/deep/ .el-input__inner {
			color: #666;
		}
	}

	/deep/ .el-input__inner {
		height: 32px;
		line-height: 32px;
	}

	/deep/ .el-date-editor .el-range__icon {
		height: 32px;
	}

	/deep/ .el-date-editor .el-range-separator {
		height: 32px;
	}

	/deep/ .el-input__suffix-inner {
		// line-height: 50px;
		display: none;
	}

	.el-select__caret .el-input__icon .el-icon-arrow-up {
		line-height: 32px;
	}

	.flex1 {
		flex: 1;
	}

	.tab {
		width: 100%;
		height: 40px;
		background-color: #f8f9fe;
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tabItem {
		width: 108px;
		height: 40px;
		line-height: 40px;
		box-sizing: border-box;
		cursor: pointer;
	}

	.borderTab {
		border: 1px solid #f2f2f2;
		border-bottom: none;
	}

	.tab-content1 {
		width: 100%;
	}

	.tab-content1-item {
		width: 49%;
		height: 135px;
		border-radius: 2px;
		box-sizing: border-box;
		background-color: #f7fbff;
		margin-bottom: 10px;

		&:nth-child(2n) {
			margin-left: 10px;
		}
	}

	.left {
		flex: 1;
		height: 104px;
	}

	.right {
		width: 156px;
		height: 104px;
		margin-right: 5px;
		background: url(../../../assets/u248.png) no-repeat;
		background-size: contain;
		background-position: center;
	}

	.tab-content2 {
		width: 100%;
	}

	/deep/ .el-table td {
		padding: 0;
	}

	/deep/ .el-table th {
		height: 36px;
		padding: 0;
	}
	.activeClass{
		width: 50px;
		height: 32px;
		background-color: #0173FE;
		color:white;
		line-height: 32px;
		text-align: center;
	}
	.img1 {
		background-image: url('../../../assets/excel.png');
		width: 40px;
		height: 40px;
		display: inline-block;
		background-size: 100% 100%;
		vertical-align: middle;
	}
	.overview {
		.leftbox {
			float: left;
			// display: inline-block;
			border-radius: 20px 20px 0 0;
			padding: 1px;
			background: linear-gradient(to bottom, #0173FE,#6BAFFF,#6BAFFF,#6BAFFF,#6BAFFF);
			font-size: 13px;
			width: 300px;
			// margin-right: 20px;
			.item {
				height: 72px;
				width: 50%;
			}
		}
		.rightbox {
			float: right;
			// display: inline-block;
			width: 535px;
			height: 220px;
			// background-color: pink;
			#main {
				width: 535px;
				height: 220px;
			}
		}
	}
</style>
